<template>
  <div class="wrapper">
    <h2>
      <i class="el-icon-document-remove"></i>修改公司简介
    </h2>
    <el-form ref="form" :model="form" :rules="rules" label-width="100px" class="demo-form-inline">
      <el-form-item class="business_license" label="企业简介：" prop="content">
        <Editor placeholder="请输入企业相关介绍" @html="text" :testcontent="form.content" height="400"></Editor>
      </el-form-item>
      <div class="saves">
        <el-button class="w100 bordQc" @click="getCancle(0)">取消</el-button>
        <el-button type="primary" class="marL20 w100" @click="saveCompany('form')">保存</el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
import Editor from "../../public/editor2";
export default {
  props: ["content"],
  components: { Editor },
  data() {
    return {
      form: {
        content: ""
      },
      rules: {
        content: [{ required: true, message: "必填", trigger: "change" }]
      }
    };
  },
  methods: {
    // 保存企业简介
    saveCompany(form) {
      this.$refs[form].validate(async valid => {
        if (valid) {
          let res = await this.$api.updateCompany(this.form);
          if (res.data.success) {
            this.$message.success(res.data.msg);
            this.$parent.getComInfo();
            this.getCancle(0);
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    text(val) {
      this.form.content = val;
    },
    getCancle(val) {
      this.$emit("getHide", val);
    }
  },
  created() {
    this.form.content = this.content;
  }
};
</script>

<style lang="less" scoped>
.wrapper {
  background: #f7f7f7;
  margin: 20px;
  margin-left: 10px;
  padding: 20px;
  h2 {
    margin-bottom: 20px;
    font-size: 18px;
    i {
      margin-right: 5px;
    }
  }
  .saves {
    width: 100%;
    transform: translateX(40%);
    margin-top: 90px;
  }
}
</style>