<template>
  <div class="wrapper">
    <h2><i class="el-icon-phone"></i>修改联系方式</h2>
    <div class="form">
      <el-form
        ref="form"
        :model="form"
        label-width="120px"
        class="demo-form-inline"
        :rules="error"
      >
        <el-form-item label="联系人：" prop="linkman">
          <el-input v-model="form.linkman" class="w430"></el-input>
        </el-form-item>
        <el-form-item label="手机号：" prop="linkphone">
          <el-input v-model="form.linkphone" class="w430"></el-input>
        </el-form-item>
        <el-form-item label="座机号：" prop="telphone">
          <el-input v-model="form.telphone" class="w430"></el-input>
        </el-form-item>
        <el-form-item label="企业邮箱：" prop="linkmail">
          <el-input v-model="form.linkmail" class="w430"></el-input>
        </el-form-item>
        <el-form-item label="企业网址：" prop="website">
          <el-input v-model="form.website" class="w430"></el-input>
        </el-form-item>
        <el-form-item label="办公地址：" prop="registerCity">
          <ThreeLink
            class="w430"
            :codeType="'provinceid'"
            :codeLevel="'-1'"
            @setMValue="
              setScasValue($event, form, [
                'provinceidValue',
                'cityidValue',
                'threeCityValue',
              ])
            "
            :defaultValue="[
              form.provinceidValue,
              form.cityidValue,
              form.threeCityValue,
            ]"
          ></ThreeLink>
        </el-form-item>
        <el-form-item label="详细地址：">
          <el-amap-search-box
            class="search-box w430"
            :search-option="searchOption"
            :on-search-result="onSearchResult"
            :default="form.address"
            ref="address"
          ></el-amap-search-box>
        </el-form-item>
        <div class="map">
          <el-amap
            vid="amapDemo"
            :center="mapCenter"
            :zoom="12"
            class="amap-demo"
          >
            <el-amap-marker
              v-for="marker in markers"
              :position="marker"
              :click="addMarker"
              :key="marker"
            ></el-amap-marker>
          </el-amap>
        </div>
        <div class="saves">
          <el-button class="w100 bordQc" @click="getCancle(0)">取消</el-button>
          <el-button
            type="primary"
            class="marL20 w100"
            @click="saveCompany('form')"
            >保存</el-button
          >
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import Editor from "../../public/editor2";
import ThreeLink from "../../selectComponents/ThreeLink";
export default {
  props: ["comIndoLlist"],
  components: { Editor, ThreeLink },
  data() {
    return {
      center: { lng: 0, lat: 0 },
      zoom: 11,
      markers: [],
      mapCenter: [116.397128, 39.916527],
      searchOption: {
        city: "北京",
        citylimit: true,
      },
      //省市区三级联动
      setScasValue(event, form, keys) {
        for (let i = 0; i < keys.length; i++) {
          let value = event[i];
          if (!value) {
            value = "";
          }
          let key = keys[i];
          form[key] = value;
        }
      },
      form: {
        linkman: "",
        linkphone: "",
        telphone: "",
        linkmail: "",
        website: "",
        latitude: "",
        longitude: "",
        provinceidValue: "",
        cityidValue: "",
        threeCityValue: "",
        address: "",
      },
      error: {
        linkman: [
          {
            pattern: /^\S+/,
            message: "您的联系人有误请重新输入",
            trigger: "blur",
          },
          {
            required: true,
            message: "您的联系人有误请重新输入",
            trigger: "blur",
          },
        ],
        telphone: [
          {
            required: true,
            message: "请输入您的座机号",
            trigger: "blur",
          },
        ],
        linkmail: [
          { required: true, message: "请输入邮箱地址", trigger: "blur" },
          {
            type: "email",
            message: "请输入正确的邮箱地址",
            trigger: ["blur", "change"],
          },
        ],
        registerCity: [
          {
            required: true,
            message: "请输入您的办公地址",
            trigger: "blur",
          },
        ],
        linkphone: [
          {
            required: true,
            message: "您的手机号码不正确请重新输入",
            trigger: "blur",
          },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: "您的手机号码不正确请重新输入",
            trigger: "blur",
          },
        ],
      },
      labelPosition: "right",
    };
  },
  methods: {
    // 保存联系人信息
    saveCompany(form) {
      this.$refs[form].validate(async (valid) => {
        if (valid) {
          let form = {
            linkman: this.form.linkman,
            linkmail: this.form.linkmail,
            linkphone: this.form.linkphone,
            telphone: this.form.telphone,
            website: this.form.website,
            latitude: this.form.latitude,
            provinceid: this.form.provinceidValue,
            cityid: this.form.cityidValue,
            threeCityid: this.form.threeCityValue,
            longitude: this.form.longitude,
            address: this.form.address,
          };
          let res = await this.$api.updateCompany(form);
          if (res.data.success) {
            this.$message.success(res.data.msg);
            this.$parent.getComInfo();
            this.getCancle(0);
          }else{
            this.$message.error(res.data.msg);
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    addMarker(val) {
      console.log(val);
      this.form.address = val;
    },
    onSearchResult(pois) {
      this.form.address = this.$refs.address.keyword;
      let latSum = 0;
      let lngSum = 0;
      console.log(pois);
      if (pois.length > 0) {
        pois.forEach((poi) => {
          let { lng, lat } = poi;
          lngSum += lng;
          latSum += lat;
          this.markers.push([poi.lng, poi.lat]);
        });
        let center = {
          lng: lngSum / pois.length,
          lat: latSum / pois.length,
        };
        this.mapCenter = [center.lng, center.lat];
      }
    },
    saveBrief() {
      this.getCancle(0);
    },
    getCancle(val) {
      this.$emit("getHide", val);
    },
  },
  created() {
    this.form = this.comIndoLlist;
    this.searchOption.city = this.form.cityid;
    console.log(this.form);
  },
};
</script>

<style lang="less" scoped>
.wrapper {
  // background: #f7f7f7;
  margin: 20px;
  margin-left: 10px;
  padding: 20px;
  h2 {
    margin-bottom: 20px;
    font-size: 18px;
    i {
      margin-right: 5px;
    }
  }
  .map {
    width: 100%;
    height: 300px;
    margin-left: 10px;
  }
  .form {
    width: 50%;
    margin: auto;
    .demo-form-inline {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
    }

    .el-form-item {
      margin-right: 40px;
    }
  }
  .saves {
    width: 100%;
    transform: translateX(40%);
    margin-top: 90px;
  }
}
.search-box {
  border: 1px solid #dcdfe6;
  box-shadow: initial;
  height: 40px;
  border-radius: 4px;
}
.search-box:hover {
  border: 1px solid #c0c4cc;
}
.search-box:focus {
  border: 1px solid #00924c;
}
</style>
