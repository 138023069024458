<template>
  <div class="wrapper">
    <h2>修改企业基本信息</h2>
    <div class="form">
      <el-form ref="form" :model="form" :rules="rules" label-width="200px" class="demo-form-inline">
        <el-form-item class="business_license" label="企业logo：" prop="logo">
          <div>
            <cd-upload v-model="form.logo" :isLogo="true" :on-success="async (res)=>{handleAvatarlicenseUrl(res,'logo')}"/>
          </div>
        </el-form-item>
        <el-form-item class="business_license" label="营业执照：" prop="licenseUrl">
          <div>
            <cd-upload v-model="form.licenseUrl" :isLogo="false" :on-success="async (res)=>{handleAvatarlicenseUrl(res,'licenseUrl')}"/>
          </div>
        </el-form-item>
        <el-form-item>
          <div class="tips">
            如果您是人力资源机构，请必须上传 人力资源服务许可证、劳务派遣经营许可证，否则无法审核通过。
          </div>
        </el-form-item>
        <el-form-item class="business_license" label="人力资源服务许可证：">
          <div>
            <cd-upload v-model="form.hrUrl" :isLogo="false" :on-success="async (res)=>{handleAvatarlicenseUrl(res,'hrUrl')}"/>
          </div>
        </el-form-item>
        <el-form-item class="business_license" label="劳务派遣经营许可证：">
          <div>
            <cd-upload v-model="form.ldUrl" :isLogo="false" :on-success="async (res)=>{handleAvatarlicenseUrl(res,'ldUrl')}"/>
          </div>
        </el-form-item>
        <el-form-item label="企业名称：" prop="companyName">
          <el-input v-model="form.companyName" class="w430" placeholder="企业名称" disabled></el-input>
        </el-form-item>
        <el-form-item label="企业类型：" prop="pr">
          <MulSelect
              class="w430"
              :code="'pr'"
              @setMValue="setSelectValue($event,'pr')"
              :defaultValue="form.pr"
          >
          </MulSelect>
        </el-form-item>
        <el-form-item label="企业规模：" class="text_L" prop="mun">
          <MulSelect
              class="w430"
              :code="'mun'"
              @setMValue="setSelectValue($event,'mun')"
              :defaultValue="form.mun"
          >>
          </MulSelect>
        </el-form-item>
        <el-form-item label="行业领域：">
          <ThreeLink
              class="w430"
              :codeType="'hy1'"
              :show_level="false"
              :codeLevel="'2'"
              @setMValue="setScasValue($event,form,['hyTop','hy'])"
              :defaultValue="[form.hyTop,form.hy]"
          ></ThreeLink>
        </el-form-item>
        <el-form-item label="统一社会信用代码：" prop="socialCredit">
          <el-input v-model="form.socialCredit" class="w430" placeholder="统一社会信用代码"></el-input>
        </el-form-item>

        <el-form-item label="工作地点：" prop="registerCity">
          <ThreeLink
              class="w430"
              :codeType="'provinceid'"
              :codeLevel="'-1'"
              @setMValue="setScasValue($event,form,['registerProvinced','registerCity','registerThreecity'])"
              :defaultValue="[form.registerProvinced,form.registerCity,form.registerThreecity]"
          ></ThreeLink>
        </el-form-item>
        <el-form-item label="详细地址：" prop="registerCity">
          <el-input v-model="form.registerDetail" placeholder="详细地址" class="w430"></el-input>
        </el-form-item>
        <el-form-item label="公司简介" prop="content">
          <Editor style="width:610px;" placeholder="详细的公司简介，有助于帮助应聘者了解您公司，吸引更多人才投递简历。" @html="text" :testcontent="form.content" height="400"></Editor>
        </el-form-item>
        <el-form-item class="saves">
          <el-button class="w100 bs_text_oo bordQc" @click="getCancle(0)">取消</el-button>
          <el-button type="primary" class="marL20 w100" @click="auditStatuSubmit('form')">提交审核</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import ThreeLink from "../../selectComponents/ThreeLink";
import MulSelect from "../../selectComponents/MulSelect";
import Editor from "@/components/public/editor.vue";

export default {
  components: {ThreeLink, MulSelect,Editor},
  data() {
    return {
      disabled: false,
      imageUrl: "",
      options: [],
      fileList: [],
      prList: [],
      munList: [],
      selectedOptions: [],
      rules: {
        companyName: [
          {required: true, message: "必填", trigger: "blur,change"}
        ],
        pr: [{required: true, message: "必填", trigger: "blur,change"}],
        mun: [{required: true, message: "必填", trigger: "blur,change"}],
        logo: [{required: true, message: "必填", trigger: "blur,change"}],
        licenseUrl: [
          {required: true, message: "必填", trigger: "blur,change"}
        ],
        hyTop: [{required: true, message: "必填", trigger: "blur,change"}],
        socialCredit: [
          {required: true, message: "必填", trigger: "blur,change"}
        ],
        registerCity: [
          {required: true, message: "必填", trigger: "blur,change"}
        ],
        cityid: [{required: true, message: "必填", trigger: "blur,change"}],
        content: [{required: true, message: "必填", trigger: "blur,change"}]
      },
      form: {
        companyName: "",
        pr: "",
        mun: "",
        logo: "",
        licenseUrl: "",
        isHr: '0',
        hrUrl: '',
        ldUrl: '',
        hyTop: "",
        hy: "",
        socialCredit: "",
        registerProvinced: "",
        registerCity: "",
        registerThreecity: "",
        registerDetail: "",
        provinceid: "p17",
        cityid: "c214",
        threeCityid: "",
        address: "",
        auditStatus: ""
      }
    };
  },
  methods: {
    text(val) {
      this.form.content = val;
    },
    // 提交表单
    auditStatuSubmit(form) {
      this.$refs[form].validate(async valid => {
        if (valid) {
          let that = this;
          that.form.isHr = (that.form.hrUrl && that.form.ldUrl) ? '1' : '0';
          let res = await that.$api.companyNeedAudit(that.form);
          if (res.data.success) {
            this.$confirm(
                "请检查您的信息是否填写正确, 一旦确定,需审核通过才能进行下次更改！是否继续?",
                "提示",
                {
                  confirmButtonText: "确定",
                  cancelButtonText: "取消",
                  type: "warning"
                }
            )
                .then(() => {
                  this.$message.success("提交审核成功,请耐心等待审核结果！");
                  this.getCancle(0);
                  this.$parent.getComInfo();
                })
                .catch(() => {
                  this.$message({
                    type: "info",
                    message: "已取消"
                  });
                });
          } else {
            this.$message.error(res.data.msg);
          }
        }
      });
      this.form.auditStatus = "1";
    },

    handleAvatarlogo(res, file) {
      console.log(res.msg);
      this.form.logo = res.msg;
    },
    handleAvatarlicenseUrl(res, type) {
      return this.form[type] = res.msg;
    },
    handleRemove() {
    },
    onProvincesChange(item) {
      this.form.hyTop = item[0];
      this.form.hy = item[1];
    },
    // 取消
    getCancle(val) {
      this.$emit("getHide", val);
    },

    //省市区三级联动
    setScasValue(event, form, keys) {
      for (let i = 0; i < keys.length; i++) {
        let value = event[i];
        if (!value) {
          value = "";
        }
        let key = keys[i];
        form[key] = value;
      }
    },
    setSelectValue(val, key) {
      this.form[key] = val;
    },
    async getComInfo() {
      let that = this;
      let res = await that.$api.getCompanyInfos();
      if (res.data.code === 200) {
        that.form = res.data.data;
        that.form.isHr = that.form.isHr === '0' ? false : true;
      }
    },
    erroupload() {
      this.$message({
        message: "图片上传失败，请重新上传！！",
        center: true
      });
    },
    beforeAvatarUpload(file) {
      let type = ["image/jpeg", "image/png"];
      const isImage = type.includes(file.type);
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isImage) {
        this.$message.error("上传图片格式不正确");
      }
      if (!isLt2M) {
        this.$message.error("图片大小不能超过 2MB!");
      }
      return isImage && isLt2M;
    }
  },

  created() {
    this.getComInfo();
  }
};
</script>


<style lang="less" scoped>
.wrapper {
  .avatar-uploader .el-upload {
    border: 1px dashed red;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }

  .avatar-uploader-icon {
    //font-size: 28px;
    //color: #8c939d;
    //width: 178px;
    //height: 178px;
    //line-height: 178px;
    //text-align: center;
  }

  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }

  /deep/ .avatar-uploader .img_avatar {
    width: 100%;
    height: 100%;
    // border-radius: 50%;
  }

  background: #f7f7f7;
  margin: 20px;
  margin-left: 10px;
  padding: 20px;
  padding-bottom: 20px;

  h2 {
    margin-bottom: 20px;
    margin-left: 60px;
    font-size: 16px;

    i {
      margin-right: 3px;
    }
  }

  .tips {
    color: #ff9100;
  }

  .saves {
    width: 100%;
    margin-top: 10px;
    transform: translateX(10%);
  }
}
</style>
