<template>
  <div class="wrapper">
    <h4>修改企业标签</h4>
    <p>最多可选择5个标签，不允许重复</p>
    <el-form ref="form" :model="form" label-width="90px" class="demo-form-inline">
      <el-form-item>
        <div class="lables">
          <el-tag
            :key="tag.index"
            v-for="tag in lableLlist"
            closable
            :disable-transitions="false"
            @close="handleClose(tag)"
          >{{tag.labelName}}</el-tag>
          <el-input
            class="input-new-tag"
            v-if="inputVisible"
            v-model="inputValue"
            ref="saveTagInput"
            size="small"
            @change="handleInputConfirm"
          ></el-input>
          <el-button v-else class="button-new-tag" size="small" @click="showInput">添加新标签</el-button>
        </div>
      </el-form-item>
      <el-form-item>
        <div class="saves">
          <el-button class="w100 bordQc" @click="getCancle(0)">取消</el-button>
          <el-button type="primary" class="marL20 w100" @click="saveCompany('form')">保存</el-button>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  props: ["lableLlist"],
  data() {
    return {
      dynamicTags: [
        "责任心",
        "执行力",
        "个人能力",
        "沟通协调能力",
        "五险一金",
        "双休"
      ],

      form: {
        lableLlist: []
      },
      inputVisible: false,
      inputValue: ""
    };
  },
  methods: {
    // 保存企业标签
    saveCompany(form) {
      this.form.lableLlist = this.lableLlist;
      this.$refs[form].validate(async valid => {
        if (valid) {
          let that = this;

          let res = await that.$api.updateCompany({
            companyLabelInDTOList: that.form.lableLlist
          });
          if (res.data.success) {
            this.$message.success("保存成功！");
            this.getCancle(0);
            this.$parent.getComInfo();
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    getCancle(val) {
      this.$emit("getHide", val);
    },
    handleClose(tag) {
      console.log(tag);
      this.lableLlist.splice(this.lableLlist.indexOf(tag), 1);
    },

    showInput() {
      this.inputVisible = true;
      this.$nextTick(_ => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
      // if (this.lableLlist.length > 4) {
      //   this.$message({
      //     type: "warning",
      //     message: "最多可选额五个企业标签"
      //   });
      //   this.inputVisible = false;
      // }
    },

    handleInputConfirm() {
      let inputValue = this.inputValue;
      if (inputValue) {
        console.log(inputValue,'dfswfrsedfe')
        let isAdd = false;
        this.lableLlist.forEach(res => {
          if (inputValue == res.labelName) {
            isAdd = true;
            return;
          }
        });
        if (isAdd) {
          this.$message.warning("该标签为重复标签！");
          return;
        }

        this.lableLlist.push({
          id: Date.now().toString(36),
          labelName: inputValue
        });
        console.log(this.lableLlist);
      }
      this.inputVisible = false;
      this.inputValue = "";
    }
  },
  created() {
    this.form.lableLlist = this.lableLlist;
  }
};
</script>

<style lang="less" scoped>
.wrapper {
  background: #f7f7f7;
  margin: 20px;
  margin-left: 10px;
  padding: 20px;

  h2 {
    margin-bottom: 20px;
    i {
      margin-right: 3px;
    }
  }
  p {
    font-size: 12px;
    color: #666666;
    margin-left: 15px;
    margin-top: 5px;
  }
  .saves {
    width: 100%;
    transform: translateX(30%);
    margin-top: 30px;
  }
  .lables {
    margin-top: 15px;
    margin-left: 25px;
  }
  .el-tag + .el-tag {
    margin-left: 10px;
  }
  .button-new-tag {
    margin-left: 10px;
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
  }
  .input-new-tag {
    width: 90px;
    margin-left: 10px;
    vertical-align: bottom;
  }
}
</style>