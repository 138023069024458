<template>
  <div class="wrapper">
    <h2>
      <i class="el-icon-camera-solid"></i>编辑工作环境
    </h2>
    <div>
      <el-upload
        class="avatar-uploader uploadimgs upload-demo"
        action="/api/app-jycy-disabled/uploadImg"
        :headers="uploadHeaders"
        list-type="picture-card"
        :file-list="UrlLists"
        :show-file-list="true"
        :limit="4"
        :on-success="handleAvatarSuccess"
        :on-remove="handeleRemove"
         :before-upload="beforeAvatarUpload"
        :on-preview="handlePictureCardPreview"
      >
        <i class="el-icon-plus"></i>
      </el-upload>
      <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过1MB</div>
    </div>

    <div class="saves">
      <el-button class="w100 bordQc" @click="getCancle(0)">取消</el-button>
      <el-button type="primary" class="marL20 w100" @click="saveCompany('form')">保存</el-button>
    </div>
  </div>
</template>

<script>
export default {
  props: ["environmentalOutDTOList"],
  data() {
    return {
      dialogImageUrl: "",
      dialogVisible: false,
      value: "",
      companyEnvironmentalInDTOList: [],
      UrlLists: [],
      environmentalUrl: {}
    };
  },
  methods: {
    // 保存工作环境
    async saveCompany() {
      console.log(this.UrlLists);
      let companyEnvironmentalInDTOList = [];
      this.companyEnvironmentalInDTOList.forEach(res => {
        console.log(res, ".dsd");
        companyEnvironmentalInDTOList.push({
          environmentalUrl: res.environmentalUrl
        });
      });

      let res = await this.$api.updateCompany({
        companyEnvironmentalInDTOList: companyEnvironmentalInDTOList
      });
      if (res.data.success) {
        this.$message.success(res.data.msg);
        this.getCancle(0);
        this.$parent.getComInfo();
      }
    },
    handleAvatarSuccess(res, file) {
      console.log("handleAvatarSuccess", res);
      this.companyEnvironmentalInDTOList.push({ environmentalUrl: res.msg });
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },

    beforeRemove(file, companyEnvironmentalInDTOList) {
      return this.$confirm("是否删除该图片?");
    },
    beforeAvatarUpload(file) {
      let type = ["image/jpeg", "image/png"];
      const isImage = type.includes(file.type);
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isImage) {
        this.$message.error("上传图片格式不正确");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isImage && isLt2M;
    },
    handeleRemove(file, fileList) {
      let newList = [];
      fileList.forEach((res)=>{
        let nowUrl = res.url;
        if(res.response){
          nowUrl = res.response.msg;
        }
        let newBean = {};
        newBean["environmentalUrl"] = nowUrl;
        newList.push(newBean);
      })
      this.companyEnvironmentalInDTOList = newList;
    },

    // erroupload() {
    //   this.$message({
    //     message: "图片上传失败，请重新上传！！",
    //     center: true
    //   });
    // },
    // handleSuccess(res, file, companyEnvironmentalInDTOList) {
    //   if (companyEnvironmentalInDTOList.length >= 2) {
    //     this.showUpload = true;
    //   }
    // },
    getCancle(val) {
      this.$emit("getHide", val);
    }
  },
  created() {
    // this.companyEnvironmentalInDTOList=this.environmentalOutDTOList.
    if (this.environmentalOutDTOList) {
      this.environmentalOutDTOList.forEach(res => {
        this.UrlLists.push({ name: res.id, url: res.environmentalUrl });
        this.companyEnvironmentalInDTOList.push({
          environmentalUrl: res.environmentalUrl
        });
      });
    }
  }
};
</script>
<style lang="less" scoped>
.wrapper {
  background: #f7f7f7;
  margin: 20px;
  margin-left: 10px;
  padding: 20px;
  h2 {
    margin-bottom: 20px;
    font-size: 18px;
    i {
      margin-right: 5px;
    }
  }
  .saves {
    width: 100%;
    transform: translateX(40%);
    margin-top: 30px;
  }
}
</style>
