<template>
  <div class="info-wrapper">
    <!-- 修改企业信息 需要审核 -->
    <div class="box" v-if="isShow.basic == 0">
      <div class="imgs-box">
        <div class="imgs">
          <img :src="comIndoLlist.logo" alt="" v-if="comIndoLlist.logo">
          <img :src="comIndoLlist.logo" alt="" class="err_logo" v-else/>
        </div>
      </div>
      <div class="text-imgs">
        <div class="tops">
          <h2>{{ comIndoLlist.companyName }}</h2>
          <div class="auto" v-if="comIndoLlist.auditStatus != 3">
            <p class="p2">{{ comIndoLlist.auditStatus | getStatus(comIndoLlist.auditStatus) }}</p>
          </div>
          <el-popover
              v-if="comIndoLlist.auditStatus == 3"
              placement="top-start"
              title="原因"
              width="200"
              trigger="hover"
              class="cursorP"
              :content="comIndoLlist.auditMsg">
            <div class="auto" slot="reference">
              <p class="p2">审核不通过</p>
            </div>
          </el-popover>
        </div>
        <div class="centers">
          <ul>
            <li>
              <p>{{ comIndoLlist.hy }}</p>
            </li>
          </ul>
          <h5>
            {{ comIndoLlist.pr }}
            <span>{{ comIndoLlist.mun }}</span>
          </h5>
        </div>
        <div class="bottoms">
          <p
              class="p1"
          >办公地址：{{ comIndoLlist.address }}</p>
        </div>
      </div>
      <div class="edit" @click="editAudit(1, comIndoLlist.auditStatus)">
        <p class="p3">
          <svg
              t="1590483064883"
              class="icon"
              viewBox="0 0 1024 1024"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              p-id="4478"
              width="16"
              height="13"
          >
            <path
                d="M853.319 551.563c0-18.85 15.28-34.132 34.131-34.132s34.132 15.28 34.132 34.132V802.1c0 65.223-48.815 119.462-110.928 119.462H196.279c-62.112 0-110.928-54.24-110.928-119.462V204.791c0-65.221 48.816-119.462 110.928-119.462H465.83c18.85 0 34.133 15.281 34.133 34.133s-15.281 34.132-34.133 34.132H196.28c-22.715 0-42.665 22.167-42.665 51.198V802.1c0 29.031 19.95 51.198 42.664 51.198h614.375c22.714 0 42.665-22.167 42.665-51.198V551.563z m-303.01-4.109c-12.523 14.09-34.097 15.358-48.186 2.835s-15.358-34.098-2.835-48.186l341.32-383.985c12.522-14.089 34.097-15.358 48.186-2.834s15.357 34.097 2.834 48.186L550.31 547.454z"
                p-id="4479"
                fill="#00924c"
            />
          </svg>
          编辑企业信息
        </p>
      </div>
    </div>
    <div class="divider" v-if="isShow.basic == 0">
      <h3>基本信息</h3>
      <div class="basic">
        <div class="info">
          <ul>
            <li>企业名称：{{ comIndoLlist.companyName }}</li>
            <li>企业类型：{{ comIndoLlist.pr }}</li>
            <li>企业规模：{{ comIndoLlist.mun }}</li>
            <li>行业领域：{{ comIndoLlist.hy }}</li>
            <li>统一社会信用代码：{{ comIndoLlist.socialCredit }}</li>
            <li>
              注册地址：{{ comIndoLlist.registerProvinced }}{{ comIndoLlist.registerCity }}{{ comIndoLlist.registerThreecity }}{{ comIndoLlist.registerDetail }}
            </li>
          </ul>
        </div>
        <viewer :images="image" class="inline_block viewer">
          <img :src="comIndoLlist.licenseUrl" alt="营业执照" title="营业执照" class="err_image imgs"/>
        </viewer>
      </div>
    </div>
    <Basic v-if="isShow.basic == 1" @getHide="getHide"></Basic>
    <!-- 修改公司简介 -->
    <div class="brief" v-if="isShow.brief == 0">
      <div class="envirol">
        <h3>公司简介</h3>
        <p class="scontent" v-html=" comIndoLlist.content"></p>
      </div>
      <div class="edit" @click="editInfo(2)">
        <p class="p3">
          <svg
              t="1590483064883"
              class="icon"
              viewBox="0 0 1024 1024"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              p-id="4478"
              width="16"
              height="13"
          >
            <path
                d="M853.319 551.563c0-18.85 15.28-34.132 34.131-34.132s34.132 15.28 34.132 34.132V802.1c0 65.223-48.815 119.462-110.928 119.462H196.279c-62.112 0-110.928-54.24-110.928-119.462V204.791c0-65.221 48.816-119.462 110.928-119.462H465.83c18.85 0 34.133 15.281 34.133 34.133s-15.281 34.132-34.133 34.132H196.28c-22.715 0-42.665 22.167-42.665 51.198V802.1c0 29.031 19.95 51.198 42.664 51.198h614.375c22.714 0 42.665-22.167 42.665-51.198V551.563z m-303.01-4.109c-12.523 14.09-34.097 15.358-48.186 2.835s-15.358-34.098-2.835-48.186l341.32-383.985c12.522-14.089 34.097-15.358 48.186-2.834s15.357 34.097 2.834 48.186L550.31 547.454z"
                p-id="4479"
                fill="#00924c"
            />
          </svg>
          编辑
        </p>
      </div>
    </div>
    <Brief v-if="isShow.brief == 2" @getHide="getHide" :content="comIndoLlist.content"></Brief>
    <!-- 修改企业标签 -->
    <div class="tags" v-if="isShow.lables == 0">
      <div>
        <h3>公司标签</h3>
        <ul>
          <li
              v-for="item in comIndoLlist.companyLabelOutDTOList"
              :key="item.index"
          >{{ item.labelName }}
          </li>
        </ul>
      </div>
      <div class="edit" @click="editInfo(0,3)">
        <p class="p3">
          <svg
              t="1590483064883"
              class="icon"
              viewBox="0 0 1024 1024"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              p-id="4478"
              width="16"
              height="13"
          >
            <path
                d="M853.319 551.563c0-18.85 15.28-34.132 34.131-34.132s34.132 15.28 34.132 34.132V802.1c0 65.223-48.815 119.462-110.928 119.462H196.279c-62.112 0-110.928-54.24-110.928-119.462V204.791c0-65.221 48.816-119.462 110.928-119.462H465.83c18.85 0 34.133 15.281 34.133 34.133s-15.281 34.132-34.133 34.132H196.28c-22.715 0-42.665 22.167-42.665 51.198V802.1c0 29.031 19.95 51.198 42.664 51.198h614.375c22.714 0 42.665-22.167 42.665-51.198V551.563z m-303.01-4.109c-12.523 14.09-34.097 15.358-48.186 2.835s-15.358-34.098-2.835-48.186l341.32-383.985c12.522-14.089 34.097-15.358 48.186-2.834s15.357 34.097 2.834 48.186L550.31 547.454z"
                p-id="4479"
                fill="#00924c"
            />
          </svg>
          编辑
        </p>
      </div>
    </div>
    <Lables
        v-if="isShow.lables == 3"
        @getHide="getHide"
        :lableLlist="comIndoLlist.companyLabelOutDTOList"
    ></Lables>
    <!-- 修改企业联系方式 -->
    <div class="connect" v-if="isShow.connect == 0">
      <div class="flex">
        <h3>企业联系方式</h3>
        <div class="edit" @click="editInfo(0, 0,4)">
          <p class="p3">
            <svg
                t="1590483064883"
                class="icon"
                viewBox="0 0 1024 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                p-id="4478"
                width="16"
                height="13"
            >
              <path
                  d="M853.319 551.563c0-18.85 15.28-34.132 34.131-34.132s34.132 15.28 34.132 34.132V802.1c0 65.223-48.815 119.462-110.928 119.462H196.279c-62.112 0-110.928-54.24-110.928-119.462V204.791c0-65.221 48.816-119.462 110.928-119.462H465.83c18.85 0 34.133 15.281 34.133 34.133s-15.281 34.132-34.133 34.132H196.28c-22.715 0-42.665 22.167-42.665 51.198V802.1c0 29.031 19.95 51.198 42.664 51.198h614.375c22.714 0 42.665-22.167 42.665-51.198V551.563z m-303.01-4.109c-12.523 14.09-34.097 15.358-48.186 2.835s-15.358-34.098-2.835-48.186l341.32-383.985c12.522-14.089 34.097-15.358 48.186-2.834s15.357 34.097 2.834 48.186L550.31 547.454z"
                  p-id="4479"
                  fill="#00924c"
              />
            </svg>
            编辑
          </p>
        </div>
      </div>

      <ul>
        <li>联系人：{{ comIndoLlist.linkman }}</li>
        <li>座机号：{{ comIndoLlist.telphone }}</li>
        <li>联系电话：{{ comIndoLlist.linkphone }}</li>
        <li>邮箱：{{ comIndoLlist.linkmail }}</li>
        <li>网址：{{ comIndoLlist.website }}</li>
      </ul>
      <p>办公地址：{{ comIndoLlist.address }}</p>
      <baidu-map
          v-if="false"
          class="map"
          :zoom="zoom"
          :center="center"
          @ready="handler"
          @click="getPoint"
          :scroll-wheel-zoom="true"
      >
        <bm-geolocation
            anchor="BMAP_ANCHOR_BOTTOM_RIGHT"
            :showAddressBar="true"
            :autoLocation="true"
        ></bm-geolocation>
        <bm-view class="map width100 h250"></bm-view>
        <bm-local-search :auto-viewport="true" :location="center" :panel="false"></bm-local-search>
        <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-navigation>
      </baidu-map>
    </div>

    <Connect v-if="isShow.connect ==4" @getHide="getHide" :comIndoLlist="comIndoLlist"></Connect>
    <!-- 修改工作图片 -->
    <!-- <BaiduMap></BaiduMap> -->
    <div class="environment" v-if="isShow.ambient == 0">
      <div class="flex">
        <h3>工作环境</h3>
        <div class="edit" @click="editInfo(0, 0, 0,5)">
          <p class="p3">
            <svg
                t="1590483064883"
                class="icon"
                viewBox="0 0 1024 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                p-id="4478"
                width="16"
                height="13"
            >
              <path
                  d="M853.319 551.563c0-18.85 15.28-34.132 34.131-34.132s34.132 15.28 34.132 34.132V802.1c0 65.223-48.815 119.462-110.928 119.462H196.279c-62.112 0-110.928-54.24-110.928-119.462V204.791c0-65.221 48.816-119.462 110.928-119.462H465.83c18.85 0 34.133 15.281 34.133 34.133s-15.281 34.132-34.133 34.132H196.28c-22.715 0-42.665 22.167-42.665 51.198V802.1c0 29.031 19.95 51.198 42.664 51.198h614.375c22.714 0 42.665-22.167 42.665-51.198V551.563z m-303.01-4.109c-12.523 14.09-34.097 15.358-48.186 2.835s-15.358-34.098-2.835-48.186l341.32-383.985c12.522-14.089 34.097-15.358 48.186-2.834s15.357 34.097 2.834 48.186L550.31 547.454z"
                  p-id="4479"
                  fill="#00924c"
              />
            </svg>
            编辑
          </p>
        </div>
      </div>
      <el-carousel :interval="4000" type="card" height="200px" :autoplay="true">
        <el-carousel-item
            v-for="item in comIndoLlist.companyEnvironmentalOutDTOList"
            :key="item.index"
        >
          <viewer :images="image" class="inline_block viewer">
            <img :src="item.environmentalUrl" alt/>
          </viewer>
        </el-carousel-item>
      </el-carousel>
    </div>
    <Ambient
        v-if="isShow.ambient == 5"
        @getHide="getHide"
        :environmentalOutDTOList="comIndoLlist.companyEnvironmentalOutDTOList"
    ></Ambient>
  </div>
</template>

<script>
import Basic from "components/enterprise/EditEnterprise/Basic";
import Brief from "components/enterprise/EditEnterprise/Brief";
import Lables from "components/enterprise/EditEnterprise/Lables";
import Connect from "components/enterprise/EditEnterprise/Connect";
import Ambient from "components/enterprise/EditEnterprise/Ambient";

export default {
  name: "businessDetails",
  components: {Connect, Lables, Basic, Brief, Ambient},
  watch: {
    "isShow.basic": function (newval, oldvalue) {
      if (newval == "0" && oldvalue == "1") {
        // this.getComInfo();
      }
    },
    "isShow.brief": function (newval, oldvalue) {
      if (newval == "0" && oldvalue == "1") {
        // this.getComInfo();
      }
    },
    "isShow.lables": function (newval, oldvalue) {
      if (newval == "0" && oldvalue == "1") {
        // this.getComInfo();
      }
    },
    "isShow.connect": function (newval, oldvalue) {
      if (newval == "0" && oldvalue == "1") {
        // this.getComInfo();
      }
    },
    "isShow.ambient": function (newval, oldvalue) {
      if (newval == "0" && oldvalue == "1") {
        // this.getComInfo();
      }
    }
  },
  data() {
    return {
      image: [],
      comIndoLlist: {},
      autoplay: true,
      content: "",
      center: {lng: 0, lat: 0},
      zoom: 11,
      // 编辑初始化
      isShow: {
        basic: "",
        brief: "",
        lables: "",
        connect: "",
        ambient: ""
      },
      comInfo: {},
      isCause: true
    };
  },
  filters: {
    getStatus(key) {
      console.log(key);
      let auditStatus = "";
      switch (key) {
        case "0":
          auditStatus = "信息待完善";
          break;
        case "1":
          auditStatus = "待审核";
          break;
        case "2":
          auditStatus = "审核通过";
          break;
      }
      return auditStatus;
    }
  },
  methods: {
    // 请求企业信息查询接口
    async getComInfo() {
      let that = this;
      let res = await that.$api.getCompanyInfo();
      if (res.data.code === 200) {
        that.comIndoLlist = res.data.data;
      }
    },
    //地图
    handler({BMap, map}) {
      this.center.lng = 116.404;
      this.center.lat = 39.915;
      this.zoom = 15;
      map.centerAndZoom(new BMap.Point(this.center.lng, this.center.lat), 15);
    },
    getPoint(e) {
      let geocoder = new BMap.Geocoder(); //创建地址解析器的实例
      // this.form.latitude = e.point.lat;
      // this.form.longitude = e.point.lng;
      // geocoder.getLocation(e.point, rs => {
      //   this.form.address = rs.address;
      // });
    },
    // 需要审核认证的编辑
    editAudit(index, auditStatus) {
      console.log(auditStatus);
      if (auditStatus == 1) {
        this.$message.warning("当前信息正在审核，不能编辑！");
        return;
      }
      if (auditStatus == 2) {
        this.$confirm(
            "您已认证，如编辑企业信息，则需重新认证，未认证通过之前所有职位将不可被查看。是否编辑？",
            "提示",
            {
              confirmButtonText: "确定",
              cancelButtonText: "取消"
              // type: 'warning'
            }
        )
            .then(() => {
              this.isShow.basic = index || 0;
            })
            .catch(() => {
              this.$message({
                type: "info",
                center: true,
                message: "已取消"
              });
              return;
            });
      } else {
        this.isShow.basic = index || 0;
      }
    },
    // 编辑简介/标签/联系方式/工作环境
    editInfo(brief, lables, connect, ambient) {
      this.isShow.brief = brief || 0;
      this.isShow.lables = lables || 0;
      this.isShow.connect = connect || 0;
      this.isShow.ambient = ambient || 0;
    },
    // 隐藏编辑信息
    getHide(index) {
      this.isShow.basic = index;
      this.isShow.brief = index;
      this.isShow.lables = index;
      this.isShow.connect = index;
      this.isShow.ambient = index;
    }
  },
  created() {
    this.getComInfo();
  }
};
</script>

<style scoped lang="less">
@import "~assets/css/enterprise/home/infomanage";

.el-carousel {
  margin-top: 20px;

  img {
    display: block;
  }
}

.el-carousel__item h3 {
  // color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 200px;
  margin: 0;
}

.el-carousel {
  .viewer {
    width: 100%;
    height: 100%;
  }
}

.el-carousel img {
  //margin-top: -72px;
  width: 100%;
  height: 100%;
}

//隐藏图片放大图标
/deep/ .el-upload-list__item-actions {
  .el-upload-list__item-preview {
    .el-icon-zoom-in {
      display: none;
    }
  }
}

/deep/ .el-upload-list--picture-card .el-upload-list__item-actions span + span {
  margin-left: 0;
}
</style>
